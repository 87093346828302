// Cabinet Grotesk
@font-face {
  font-family: "CabinetGrotesk-Variable";
  src: url("../fonts/CabinetGrotesk/CabinetGrotesk-Variable.woff2")
      format("woff2"),
    url("../fonts/CabinetGrotesk/CabinetGrotesk-Variable.woff") format("woff"),
    url("../fonts/CabinetGrotesk/CabinetGrotesk-Variable.ttf")
      format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: CabinetGrotesk-Variable;
  --font-body: CabinetGrotesk-Variable;
}
