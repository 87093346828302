.root {
  overflow: hidden;
}

#headlessui-portal-root {
  @apply text-neutral-900 dark:text-neutral-200 text-base;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.z-max {
  z-index: 999999999;
}

//
.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-8;
}

.nc-hero-field-padding--small {
  @apply py-3 px-4 md:py-3 md:px-7 xl:px-8;
}

.nc-hero-field-focused {
  @apply rounded-full dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.ListingDetailPage {
  .nc-hero-field-focused {
    @apply rounded-3xl shadow-2xl;
  }
  &.nc-ListingExperiencesDetailPage {
    .ExperiencesDateSingleInput .nc-hero-field-focused {
      @apply rounded-r-none;
    }
    .listingExperiencesDetailPage__GuestsInput .nc-hero-field-focused {
      @apply rounded-l-none;
    }
  }

  .DateRangePickerInput_clearDates,
  .SingleDatePickerInput_clearDate {
    @apply rounded-full bg-neutral-200/90 dark:bg-white/10;
    svg {
      @apply w-2 fill-neutral-700 dark:fill-neutral-300;
    }
  }
}

.nc-ListingStayDetailPage__guestsInput {
  .nc-hero-field-focused {
    @apply rounded-t-none;
  }
}
.nc-ListingStayDetailPage__stayDatesRangeInput {
  .nc-hero-field-focused {
    @apply rounded-b-none;
  }
}

.nc-hero-field-focused--2 {
  @apply rounded-full dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.nc-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

// LISTING PAGE DETAIL
.listingSectionSidebar__wrap {
  @apply w-full flex flex-col rounded-2xl border-b border-t border-l border-r border-neutral-200 dark:border-neutral-700 space-y-6 xl:space-y-7 pb-10 p-2 sm:p-4 xl:px-8 xl:py-6;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.rc-slider-track {
  @apply bg-primary-400;
}
.rc-slider-handle {
  @apply border-primary-300;
}

//
.nc-PropertyCardH {
  .nc-BtnLikeIcon--liked {
    @apply text-red-500 dark:text-red-500;
  }
}

//
.ncSectionLogos {
  img {
    @apply w-full;
    max-width: 200px;
  }
}

//
.modalPhotos-single-gallery {
  .glide__bullet--active {
    @apply block;
  }
}

.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

.FooterNav--hide {
  @apply translate-y-full;
}

@keyframes myblur {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nc-custom-shadow-1 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

// ENZO
.text-balance {
  text-wrap: balance;
}

.bg-full {
  background-position: 100%;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.section-heading {
  & span {
    @apply text-main;
  }
}

.default-heading {
  @apply text-3xl md:text-4xl md:leading-tight font-bold;
}

p {
  @apply block text-lg text-cGrey font-medium;
}

ul {
  &.default {
    & li {
      @apply flex items-center gap-3 text-lg lg:text-2xl text-cGrey font-medium before:relative before:block before:flex-shrink-0 before:w-4 before:h-4 before:bg-cover before:rounded-full before:bg-no-repeat before:bg-center;
      &:before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M13.7,8.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L9,10.6L7.7,9.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4 l2,2c0.4,0.4,1,0.4,1.4,0L13.7,8.7z'/%3E%3C/svg%3E%0A");
      }
    }

    &.blue {
      & li {
        @apply before:bg-cBlue;
      }
    }

    &.red {
      & li {
        @apply before:bg-cRed;
      }
    }
  }
}

.page-form-wrap {
  @apply w-full flex flex-col rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 p-4 xl:p-8 bg-white;
}

.form-col {
  @apply flex gap-6 md:gap-9;
}

.form-field {
  @apply block w-full border-neutral-200 focus:border-main focus:border-opacity-10 focus:ring focus:ring-main focus:ring-opacity-10 bg-white h-11 px-4 py-3 text-sm font-normal rounded-2xl #{!important};
}

.upload--container {
  @apply flex flex-col justify-between;
}

.file--upload {
  @apply mt-3 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-200 border-dashed rounded-md;

  & .file-upload-wrapper {
    @apply space-y-1 text-center;

    & .file-upload-input {
      @apply flex justify-center items-center text-sm text-neutral-6000;

      & .file-upload-label {
        @apply relative cursor-pointer rounded-md font-medium;
      }

      & .remove-icon {
        @apply cursor-pointer rounded-full bg-slate-200 hover:scale-110 transition-transform px-2 self-center;
      }
    }

    & .upload-info {
      @apply text-xs text-neutral-500 dark:text-neutral-400;
    }
  }
}

.progress-bar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: red;
  transform-origin: 0%;
  z-index: 999;
}

table {
  border: 1px solid #b3adad;
  border-collapse: collapse;
  padding: 5px;

  & th {
    border: 1px solid #b3adad;
    padding: 5px;
    background: #f0f0f0;
    color: #313030;
  }
  & td {
    border: 1px solid #b3adad;
    text-align: center;
    padding: 10px;
    background: #ffffff;
    color: #313030;
    @apply text-lg text-cGrey font-medium;
  }
}

// kvkk
.gdpr-wrapper {
  @apply flex flex-col gap-4;

  & h4 {
    @apply text-xl font-bold text-black mt-6;

    &.form-head {
      @apply text-2xl font-bold text-black mt-0;
    }
  }

  & ul {
    @apply flex flex-col gap-[15px] pl-[35px] list-disc;

    & li {
      @apply text-lg text-cGrey font-medium;
    }
  }
}

.disable:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  cursor: not-allowed;
  opacity: 0.5;
}

.disable-file:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  cursor: not-allowed;
  opacity: 0.5;
  z-index: 1;
}

.tooltip-after:after {
  content: " ";
  position: absolute;
  border-style: solid;
  width: 0;
  margin-left: -5px;
  left: 10px;
  top: -5px;
  border-bottom-width: 5px;
  border-bottom-color: #f4f4f4;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  left: 10px;
  top: -5px;
  font-size: 0;
  line-height: 0;
}

@media only screen and (max-width: 767px) {
  .gdpr-control {
    transform: translateX(-100%) !important;
  }
}
