.nc-header-bg {
  @apply backdrop-blur-2xl backdrop-filter;
}

.nav-wrapper {
  &:after {
    @apply content-[""] absolute h-1 inset-x-0 lg:inset-x-4 2xl:inset-x-[128px] top-auto -bottom-1;
  }

  &:after {
    background-image: linear-gradient(
      to right,
      #ffc41b 12.5%,
      #df1683 12.5%,
      #df1683 25%,
      #3776ba 25%,
      #3776ba 37.5%,
      #ce1f3a 37.5%,
      #ce1f3a 50%,
      #ffc41b 50%,
      #ffc41b 62.5%,
      #df1683 62.5%,
      #df1683 75%,
      #3776ba 75%,
      #3776ba 87.5%,
      #ce1f3a 87.5%
    );
  }
}
